<template>
  <div class="sidebar w-64 fixed flex flex-col z-20 left-0 h-screen bg-gray-100 border-r-2 border-gray-300 text-gray-700 overflow-y-auto">
    <div class="flex justify-center mb-8 mt-5 p-5">
      <img src="~@/assets/tia/tia.png" class="logo-tia w-36"/>
    </div>
    <div v-for="(nav, index) in navItems" :key="'nav-' + index">

      <router-link 
        v-if="!nav.children" 
        :to="nav.url" 
        class="flex items-center gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow-secondary"
        :class="{ 'bg-yellow-secondary': isActive(nav.url)}"
        >
        
        <component :is="nav.icon" />
        <span class="font-medium text-base">{{ nav.name }}</span>
      </router-link>

      <template v-else>
        <input
          type="radio"
          :value="nav.name"
          name="radio"
          :id="'checkbox' + index"
          v-model="selected.item"
          class="hidden"
          @click="toggleMenu(nav.name)"
        />

        <label :for="'checkbox' + index" class="cursor-pointer flex items-center justify-between gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow-secondary" :key="'label + index'">
        <div class="flex items-center gap-2">
          <component :is="nav.icon" />
          <span class="font-medium text-base">{{ nav.name }}</span>
        </div>
        <Chevron :direction="selected.item === nav.name ? 'up' : 'down'" width="12"  />
      </label>


        <div class="bg-gray-100 pl-5" v-if="isMenuOpen(nav.name)">
          <div v-for="(childNav, idx) in nav.children" :key="'child-nav-' + idx">
            <router-link 
              v-if="!childNav.children" 
              :to="childNav.url" 
              class="flex items-center gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow-secondary"
              :class="{ 'bg-yellow-secondary': isActive(childNav.url)}"
              >

              <component :is="childNav.icon" />
              <span class="font-medium text-sm">{{ childNav.name }}</span>
            </router-link>

            <template v-else>
              <input
                type="radio"
                :value="childNav.name"
                name="radio"
                :id="'child-checkbox' + idx"
                v-model="selected.child"
                class="hidden"
                @click="toggleSubMenu(childNav.name)"
              />
              <label :for="'child-checkbox' + idx" class="cursor-pointer flex items-center justify-between gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow-secondary">
                <div class="flex items-center gap-2">
                  <component :is="childNav.icon" />
                  <span class="font-medium text-sm">{{ childNav.name }}</span>
                </div>
                <Chevron :direction="isSubMenuOpen(childNav.name) ? 'up' : 'down'" width="12" />
              </label>
              
              <div class="bg-gray-100" v-if="isSubMenuOpen(childNav.name)">
                <router-link 
                  v-for="(grandChildNav, idx) in childNav.children" 
                  :key="'grand-child-nav-' + idx" 
                  :to="grandChildNav.url" 
                  class="pl-5  ml-5 flex items-center gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow-secondary"
                  :class="{ 'bg-yellow-secondary': isActive(grandChildNav.url)}"
                  >

                  <component :is="grandChildNav.icon" />
                  <span class="font-medium text-sm">{{ grandChildNav.name }}</span>
                </router-link>
              </div>

            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import Chevron from '@/components/Icons/Chevron'

export default {
  components: {
    Chevron
  },
  props: {
    navItems: Array
  },
  computed: {
    ...mapGetters('sidebar', ['openMenu', 'openSubMenu']),
    selected() {
      return {
        item: this.openMenu,
        child: this.openSubMenu
      }
    }
  },
  methods: {
    ...mapActions('sidebar', ['setOpenMenu', 'setOpenSubMenu']),
    toggleMenu(menuName) {
      if (this.openMenu === menuName) {
        this.setOpenMenu(null);
      } else {
        this.setOpenMenu(menuName);
        this.setOpenSubMenu(null);
      }
    },
    toggleSubMenu(childName) {
      if (this.openSubMenu === childName) {
        this.setOpenSubMenu(null);
      } else {
        this.setOpenSubMenu(childName);
      }
    },
    isMenuOpen(menuName) {
      return this.openMenu === menuName;
    },
    isSubMenuOpen(subMenuName) {
      return this.openSubMenu === subMenuName;
    },
    isActive(route) {
      return this.$route.path === route;
    }

  }
}
</script>
