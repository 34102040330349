var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sidebar w-64 fixed flex flex-col z-20 left-0 h-screen bg-gray-100 border-r-2 border-gray-300 text-gray-700 overflow-y-auto"
  }, [_vm._m(0), _vm._l(_vm.navItems, function (nav, index) {
    return _c('div', {
      key: 'nav-' + index
    }, [!nav.children ? _c('router-link', {
      staticClass: "flex items-center gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow-secondary",
      class: {
        'bg-yellow-secondary': _vm.isActive(nav.url)
      },
      attrs: {
        "to": nav.url
      }
    }, [_c(nav.icon, {
      tag: "component"
    }), _c('span', {
      staticClass: "font-medium text-base"
    }, [_vm._v(_vm._s(nav.name))])], 1) : [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selected.item,
        expression: "selected.item"
      }],
      staticClass: "hidden",
      attrs: {
        "type": "radio",
        "name": "radio",
        "id": 'checkbox' + index
      },
      domProps: {
        "value": nav.name,
        "checked": _vm._q(_vm.selected.item, nav.name)
      },
      on: {
        "click": function click($event) {
          return _vm.toggleMenu(nav.name);
        },
        "change": function change($event) {
          return _vm.$set(_vm.selected, "item", nav.name);
        }
      }
    }), _c('label', {
      key: 'label + index',
      staticClass: "cursor-pointer flex items-center justify-between gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow-secondary",
      attrs: {
        "for": 'checkbox' + index
      }
    }, [_c('div', {
      staticClass: "flex items-center gap-2"
    }, [_c(nav.icon, {
      tag: "component"
    }), _c('span', {
      staticClass: "font-medium text-base"
    }, [_vm._v(_vm._s(nav.name))])], 1), _c('Chevron', {
      attrs: {
        "direction": _vm.selected.item === nav.name ? 'up' : 'down',
        "width": "12"
      }
    })], 1), _vm.isMenuOpen(nav.name) ? _c('div', {
      staticClass: "bg-gray-100 pl-5"
    }, _vm._l(nav.children, function (childNav, idx) {
      return _c('div', {
        key: 'child-nav-' + idx
      }, [!childNav.children ? _c('router-link', {
        staticClass: "flex items-center gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow-secondary",
        class: {
          'bg-yellow-secondary': _vm.isActive(childNav.url)
        },
        attrs: {
          "to": childNav.url
        }
      }, [_c(childNav.icon, {
        tag: "component"
      }), _c('span', {
        staticClass: "font-medium text-sm"
      }, [_vm._v(_vm._s(childNav.name))])], 1) : [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.selected.child,
          expression: "selected.child"
        }],
        staticClass: "hidden",
        attrs: {
          "type": "radio",
          "name": "radio",
          "id": 'child-checkbox' + idx
        },
        domProps: {
          "value": childNav.name,
          "checked": _vm._q(_vm.selected.child, childNav.name)
        },
        on: {
          "click": function click($event) {
            return _vm.toggleSubMenu(childNav.name);
          },
          "change": function change($event) {
            return _vm.$set(_vm.selected, "child", childNav.name);
          }
        }
      }), _c('label', {
        staticClass: "cursor-pointer flex items-center justify-between gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow-secondary",
        attrs: {
          "for": 'child-checkbox' + idx
        }
      }, [_c('div', {
        staticClass: "flex items-center gap-2"
      }, [_c(childNav.icon, {
        tag: "component"
      }), _c('span', {
        staticClass: "font-medium text-sm"
      }, [_vm._v(_vm._s(childNav.name))])], 1), _c('Chevron', {
        attrs: {
          "direction": _vm.isSubMenuOpen(childNav.name) ? 'up' : 'down',
          "width": "12"
        }
      })], 1), _vm.isSubMenuOpen(childNav.name) ? _c('div', {
        staticClass: "bg-gray-100"
      }, _vm._l(childNav.children, function (grandChildNav, idx) {
        return _c('router-link', {
          key: 'grand-child-nav-' + idx,
          staticClass: "pl-5  ml-5 flex items-center gap-2 transition-all duration-150 py-3 px-5 hover:bg-yellow-secondary",
          class: {
            'bg-yellow-secondary': _vm.isActive(grandChildNav.url)
          },
          attrs: {
            "to": grandChildNav.url
          }
        }, [_c(grandChildNav.icon, {
          tag: "component"
        }), _c('span', {
          staticClass: "font-medium text-sm"
        }, [_vm._v(_vm._s(grandChildNav.name))])], 1);
      }), 1) : _vm._e()]], 2);
    }), 0) : _vm._e()]], 2);
  })], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-center mb-8 mt-5 p-5"
  }, [_c('img', {
    staticClass: "logo-tia w-36",
    attrs: {
      "src": require("@/assets/tia/tia.png")
    }
  })]);
}]

export { render, staticRenderFns }